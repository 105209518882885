
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiBase, ApiCreditor, ApiDebtor } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { OCPSDepositManagementTableFilter } from "@/core/directive/interface/finacialReport";
import { CreditorOption } from "@/core/directive/interface/creditor";

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-deposit-management-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<OCPSDepositManagementTableFilter>({
      order_date_start: "",
      order_date_end: "",
      creditor_entity_id: "",
      debtor_entity_id: "",
      transaction_date_start: "",
      transaction_date_end: "",
      external_transaction_platform: "",
      currency: "",
    });

    const options = ref({
      entityLoading: false,
      entityOptions: [] as CreditorOption[],
      paymentGatewayOptions: [] as TaggingItem[],
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
    });

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["payment_gateway"],
      });
      if (data.code == 0) {
        options.value.paymentGatewayOptions = data.data.payment_gateway.items;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      searchEntityItems,
      submit,
      handleReset,
    };
  },
});
